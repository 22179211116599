import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNavLink, IonPage, IonRow, IonSpinner, IonTitle, IonToggle, IonToolbar, useIonModal } from '@ionic/react';

import {restPatch, useRestLoad} from '../../hooks/WildWebHttp'
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NodeItem from '../../components/NodeItem';
import MaintainNodeItem from '../../components/MaintainNodeItem';
import MaintainNodePicker from '../../modals/MaintainTypePicker';
import MaintainExplorer from '../../modals/MaintainExplorer';
import { checkmarkOutline, chevronForwardOutline, documentTextOutline, folderOutline, handRightOutline } from 'ionicons/icons';
import './MaintainType.css';


const Node: React.FC = () => {
  
  const history = useHistory();
  
  const {uuid} = useParams<{uuid: string}>()
  const [type,getType] = useRestLoad('/rest/maintain/type/' );
  const [fields,setFields] = useState<{[any:string]:any}>({});
  

  const [nodePickerPresent, nodePickerDismiss] = useIonModal(MaintainNodePicker, {
    close:()=>nodePickerDismiss(),confirm:(parent:{[any:string]:any})=>{
      setFields(Object.assign(fields,{parentUuid:parent.uuid,parentName:parent.name}));
      nodePickerDismiss()
    }
  });

  const [detailPickerPresent, detailPickerDismiss] = useIonModal(MaintainExplorer, {
    url_:fields.url,
    close:()=>detailPickerDismiss()
  });

  console.log('uuid:' + uuid);



  useEffect(()=>{
    getType(uuid)
  },[uuid]);

  useEffect(()=>{
    if (type.ready){ 
      if(type.data.parent){
        setFields({
          parentUuid:type.data.parent.uuid,
          parentName:type.data.parent.name,
          name:type.data.detail.name
        });
      }else{
        setFields({
          name:type.data.detail.name
        });
      }
    }
  },[type]);


  useEffect(()=>{
    // let h = $('#content').height();
    // console.log('======' + h);
    // if (h){
    //   // $('#frame').height(h-120);
    // }
  });


    return type.ready?
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>维护节点:{type.data.detail.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton  onClick={()=>{
              restPatch('/rest/maintain/type/' + uuid,{
                  parentUuid:fields.parentUuid,
                  name:fields.name
              },()=>{
                getType(uuid);
                // alert('success!');
                // history.goBack();
              });

            }} >
              <IonIcon icon={checkmarkOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonBreadcrumbs class='breadcrumbs'>

          {
            type.data.paths.map((path:any)=>(
              <IonBreadcrumb href={'/maintain/type/' + path.uuid} target="_self" key={path.uuid}>{path.name}</IonBreadcrumb>
            ))
          }
          </IonBreadcrumbs>

        </IonHeader>
        <IonContent id="content">

        <IonCard className='node_card'>

          <IonItem onClick={()=>nodePickerPresent()} button>
            <IonLabel>
              { fields.parentUuid? 
              <h4>{fields.parentName}(父节点)</h4>
              :<h4>未有父节点)</h4>
              }
            </IonLabel>
            <IonLabel slot='end'>
              <p>设置</p>
            </IonLabel>
          </IonItem>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>详情</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>UUID</IonLabel>
              <IonLabel slot='end'><p>{ uuid}</p></IonLabel>
            </IonItem>         
            <IonItem>
              <IonLabel>Name</IonLabel>
              <IonInput value={fields.name}  className='rightInput'
                onIonChange={(e)=>setFields(Object.assign(fields,{name:e.detail.value}))} >
              </IonInput>
            </IonItem>


            
          </IonItemGroup>

        </IonCard>

        
        {
          type.data.childs && type.data.childs.length > 0 &&
          <IonItemGroup>

            <IonItemDivider>
              <IonLabel>子节点</IonLabel>
            </IonItemDivider>

            {
              type.data.childs.map((node:any)=>(
                <IonItem routerLink={ '/maintain/type/' + node.uuid } key={node.uuid} detail button lines='none'>
                <IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
                <IonLabel>
                  {node.name}
                </IonLabel>
              </IonItem>
              ))
            }

          </IonItemGroup>
        }
        { type.data.nodes && type.data.nodes.length > 0 &&
          <IonItemGroup>

          <IonItemDivider>
            <IonLabel>子节点</IonLabel>
          </IonItemDivider>
          {
            
            type.data.nodes.map((node:any)=>(
              <IonItem routerLink={ '/maintain/node/' + node.uuid } key={node.uuid} detail button lines='none'>
              <IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
              <IonLabel>
                {node.name}
              </IonLabel>
            </IonItem>
            ))
          }
          </IonItemGroup>
        }
        </IonContent>
      </IonPage>
      :<center><IonSpinner></IonSpinner></center>
};

export default Node;

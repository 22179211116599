import {EventEmitter} from 'eventemitter3';
import {createContext} from 'react';

interface Json {[key:string]:any};

let account:Json = {}
let setAccount:(arg:any)=>void = (arg:any)=>{}

export const AppContext = createContext({
    client:undefined,setClient:undefined, 
    account:undefined,setAccount:undefined
});

export const eventEmitter = new EventEmitter();

export var serverBaseUrl = window.location.origin.includes('8101')? window.location.origin.replace('8101','8081') :window.location.origin + '/serv'; 

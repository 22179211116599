import React, { useState } from 'react';
import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonNavLink, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonModal ,IonInput, IonLabel, IonList, IonButton} from '@ionic/react';
import MaintainNodePicker from './MaintainTypePicker';
import { checkmarkOutline, chevronForwardOutline, closeOutline, logoWebComponent } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { restPost } from '../hooks/WildWebHttp';
import $ from 'jquery';


const MaintainExplore: React.FC<{url_:string,close:()=>void}> = ({url_,close}) => {
  
    
    const [name,setName] = useState<string>()
    const [url,setUrl] = useState<string>(url_)

    const [frameUrl,setFrameUrl] = useState<string>(url)

      
  return (

    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton  onClick={ close} >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonInput value={url} placeholder="https://" onIonChange={(e)=>setUrl(e.detail.value!)} style={{backgroundColor:'white' }}></IonInput>
          <IonButtons slot='end' onClick={()=>setFrameUrl(url)}>
            <IonButton>
              <IonIcon icon={logoWebComponent} ></IonIcon>
            </IonButton>
          </IonButtons>

        </IonToolbar>


        </IonHeader>
        <IonContent id="content">

          <iframe id="frame_id" src={frameUrl} width="100%" height="100%" onLoad={()=>{
            console.log('onloaded...');
            console.log(window.frames[0]);
            
            }}></iframe>
        
        </IonContent>
      </IonPage>


  );
}
export default MaintainExplore;
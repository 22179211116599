// https://ionicframework.com/docs/react/your-first-app
// https://mattferderer.com/difference-between-array-types-in-typescript

import { useState, useEffect } from "react";
import { isPlatform } from "@ionic/react";

import { Preferences } from "@capacitor/preferences";



export function usePreferences(key:string) {

  const [json, setJson] = useState<{[key:string]:any}>();



  const saveJson = (_json:{[key:string]:any}) => {

    // let v:{[key:string]:any} = {};
    // Object.assign(v,json);
    // Object.assign(v,_json);

    console.log('Preferences write, key:' + key + ', value:' + JSON.stringify(_json) );
  
    if (_json == undefined) _json = null;

    setJson(_json);

    Preferences.set({key:key,value: JSON.stringify(_json)});
  
  }
   
  useEffect(()=>{
    (async ()=>{

      
      let {value} = await Preferences.get({key:key});

      console.log('Preferences read, key:' + key + ", value:" + value );


      if (!value || value == 'undefined' || value == 'null') {
        setJson(null); 
      } else {
        setJson(JSON.parse(value)); 
      }

    })().then(()=>{
      // console.log('use state called4 !!');
    });;
  },[]);

  let ret:[{[key:string]:any},(arg:any)=>void] = [json,saveJson]
  
  return ret;
}


import { IonContent, IonGrid, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import NodeItem from '../components/NodeItem';
import { useRestGet, useRestList, useRestLoad } from '../hooks/WildWebHttp';
import './Tab2.css';

const Tab2: React.FC = () => {

  const [favors,listFavors] = useRestList('/rest/user/favors');
  
  useEffect(()=>{
    listFavors();
  },[]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>收藏</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
        <IonGrid fixed={true}>
        {
          favors &&
          favors.map((node:any)=>(
            <NodeItem node={node} key={node.uuid}></NodeItem>
          ))
        }
        </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default Tab2;

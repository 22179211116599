import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNavLink, IonNote, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonModal } from '@ionic/react';

import {restPatch, useRestGet, useRestList, useRestLoad} from '../../hooks/WildWebHttp'
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NodeItem from '../../components/NodeItem';
import MaintainNodeItem from '../../components/MaintainNodeItem';
import MaintainNodePicker from '../../modals/MaintainTypePicker';
import MaintainExplorer from '../../modals/MaintainExplorer';
import { checkmarkOutline, chevronForwardOutline, documentTextOutline, folderOutline, handRightOutline } from 'ionicons/icons';
import { splitArray } from '../../common/utils';
import SiteCard from '../../components/SiteCard';



const Node: React.FC = () => {
  
  const history = useHistory();
  
  const [sites,listSites] = useRestList('/rest/maintain/sites' );


  useEffect(()=>{
    listSites();
  },[]);



  return !sites?<IonPage>Loading ...</IonPage>
      :  
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>维护站点</IonTitle>
          
        </IonToolbar>
        </IonHeader>

        <IonContent id="content">

        <IonGrid >
          {
            splitArray(sites,4).map((rows:any,i:any)=>( 
              
              <IonRow key={i}>
              {
                rows.map((col:any,j:any)=>(
                  <IonCol key={col.uuid || j} size="12" size-sm="6" size-lg="3">
                    <SiteCard node={col} key={col.uuid}></SiteCard>
                    </IonCol>  
                ))
              }
            </IonRow>

            ))
          }
        </IonGrid>  

        </IonContent>
      </IonPage>

};

export default Node;

import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNavLink, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonModal } from '@ionic/react';

import {useRest, useRestLoad} from '../hooks/WildWebHttp'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NodeItem from '../components/NodeItem';
import MaintainNodeItem from '../components/MaintainNodeItem';
import { addCircleOutline, checkmarkCircleOutline, checkmarkOutline, closeOutline, documentTextOutline, folderOutline } from 'ionicons/icons';
import MaintainAddType from './MaintainAddType';


const Node: React.FC<{close:()=>void,confirm:(pNode:{[key:string]:any})=>void}> = ({close,confirm}) => {
  
  const [uuid,setUuid] = useState('265a37db-7601-42f9-b676-651e87ec55e9');
  
  const [type,getType,patchType] = useRest('/rest/maintain/type/');

  const [fields,setFields] = useState<{[any:string]:any}>({});
  
  const [addTypePresent, addTypeDismiss] = useIonModal(MaintainAddType, {
    parent:type,
    close:()=>addTypeDismiss(),confirm:(pNode:{[key:string]:any})=>{
        addTypeDismiss()
        getType(uuid)
    }
  });
  
  useEffect(()=>{
    getType(uuid)
  },[uuid]);

  useEffect(()=>{

    if (type){ 
        setFields({
          name:type.detail.name
        });
    }
  },[type]);


  if (!type){
    return <IonPage></IonPage>
  }else{

    return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton  onClick={ close} >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          {
            type.detail &&
            <IonButtons slot="end">
              <IonButton  onClick={()=>confirm(type.detail)} >
                <IonIcon icon={checkmarkOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          }
          
          <IonTitle>请选择分类</IonTitle>
        </IonToolbar>

        <IonBreadcrumbs class='breadcrumbs'>
          {
            type.paths.map((path:any)=>(
              <IonBreadcrumb onClick={()=>setUuid(path.uuid) } key={path.uuid}>{path.name}</IonBreadcrumb>
            ))
          }
        </IonBreadcrumbs>

        </IonHeader>
        <IonContent id="content">

        { type.detail &&
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>详情</IonLabel>
              <IonButton slot='end' fill='clear' onClick={()=>{
                console.log('fields: ' + JSON.stringify(fields));
                patchType(uuid,fields);
              }}>
                <IonIcon slot='icon-only' icon={checkmarkCircleOutline}></IonIcon>
              </IonButton>
            </IonItemDivider>
            {/* <IonItem>
              <IonLabel>UUID</IonLabel>
              <IonLabel slot='end'><p>{type.detail.uuid}</p></IonLabel>
            </IonItem>          */}
            <IonItem>
              <IonLabel>Name</IonLabel>
              <IonInput value={fields.name}  className='rightInput'
                onIonChange={(e)=>setFields(Object.assign(fields,{name:e.detail.value}))} >
              </IonInput>
              
            </IonItem>
     
            
          </IonItemGroup>
        }
        
        { type.childs &&
        <IonItemGroup>
          <IonItemDivider>
            <IonLabel>子节点</IonLabel>
            <IonButton slot='end' fill='clear' onClick={()=>{
              addTypePresent();
            }}>
              <IonIcon slot='icon-only' icon={addCircleOutline}></IonIcon>
            </IonButton>

          </IonItemDivider>

          {
            type.childs.map((node:any)=>(
              <IonItem onClick={()=>setUuid(node.uuid) } key={node.uuid} detail button lines='none'>
                <IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
                <IonLabel>
                  {node.name}
                </IonLabel>
              </IonItem>
            ))
          }

          
        </IonItemGroup>
        }
        </IonContent>
      </IonPage>
    );
  }
};

export default Node;

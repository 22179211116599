import { IonBackButton, IonButton, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonMenu, IonMenuButton, IonNavLink, IonPage, IonRouterLink, IonRow, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Search.css';
import {  useRestSearchKeyword } from '../hooks/WildWebHttp';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NodeItem from '../components/NodeItem';
import { splitArray } from '../common/utils';
import NodeCard from '../components/NodeCard';


const Search: React.FC = () => {

  const {keyword} = useParams<{keyword: string}>()
  const [searcnResult,searchKeyword] = useRestSearchKeyword('/rest/search/');
  const [searchText, setSearchText] = useState(keyword);

  useEffect(()=>{
    searchKeyword(keyword)
  },[keyword]);

  const search = (e:any)=>{
    console.log('search:' + searchText);
    if (e.key == 'Enter'){
      searchKeyword(searchText);
      
    }
  }

  return (
 
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          
        </IonToolbar>

        <IonToolbar>
          <IonSearchbar value={searchText}  animated={true} placeholder="搜索" enterkeyhint="done" inputmode="search" style={{marginTop:'6px'}} onIonChange={e => setSearchText(e.detail.value!)} onKeyPress={search} ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
        {
          searcnResult.ready && 
          splitArray(searcnResult.data.nodes,4).map((rows:any,i:any)=>( 
            
            <IonRow key={i}>
            {
              rows.map((col:any,j:any)=>(
                <IonCol key={col.uuid || j} size="12" size-sm="6" size-lg="3">
                  <NodeCard node={col} key={col.uuid}></NodeCard>
                </IonCol>  
              ))
            }
          </IonRow>

          ))

        }
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Search;

import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonNavLink, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tag.css';
import {useRestLoad} from '../hooks/WildWebHttp'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import NodeItem from '../components/NodeItem';

const Node: React.FC = () => {
  
  const {uuid} = useParams<{uuid: string}>()
  const [tag,getTag] = useRestLoad('/rest/tag/' );

  useEffect(()=>{
    getTag(uuid)
  },[uuid]);

  useEffect(()=>{
    // let h = $('#content').height();
    // console.log('======' + h);
    // if (h){
    //   // $('#frame').height(h-120);
    // }
  });

  if (!tag.ready){
    return <IonPage></IonPage>
  }else{

    return (
      <IonPage>
        <IonGrid fixed={true}>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>{tag.data.name}</IonTitle>
        </IonToolbar>
        <IonBreadcrumbs class='breadcrumbs'>
          <IonBreadcrumb href={'/'} key={'home'}>首页</IonBreadcrumb>
          </IonBreadcrumbs>

        </IonHeader>
        <IonContent id="content">

          {
            tag.data.nodes.map((child:any)=>(
              <NodeItem node={child} key={child.uuid}></NodeItem>
            ))
          }
          
        </IonContent>
        </IonGrid>
      </IonPage>
    );
  }
};

export default Node;

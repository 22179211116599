import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import { addCircle, addOutline, colorFilterOutline, documentTextOutline, ellipsisVerticalOutline, folderOutline, listOutline, pause, pauseOutline, peopleOutline, readerOutline, removeOutline } from 'ionicons/icons';
import { useContext, useEffect } from 'react';
import { AppContext } from '../common/global';
import ExploreContainer from '../components/ExploreContainer';
import MaintainNodeItem from '../components/MaintainNodeItem';
import NodeItem from '../components/NodeItem';
import { useRestGet, useRestList, useRestLoad } from '../hooks/WildWebHttp';
import { eventEmitter } from '../common/global';
import MaintainAddType from '../modals/MaintainAddType';
import MaintainAddNode from '../modals/MaintainAddNode';

const Maintain: React.FC = () => {

  const [map,getMap] = useRestGet('/rest/maintain');
  
  const [addTypePresent, addTypeDismiss] = useIonModal(MaintainAddType, {
    parent:null,
    close:()=>addTypeDismiss(),confirm:(pNode:{[key:string]:any})=>{
        addTypeDismiss()
    }
  });

  const [addNodePresent, addNodeDismiss] = useIonModal(MaintainAddNode, {
    close:()=>addNodeDismiss(),confirm:(pNode:{[key:string]:any})=>{
      addNodeDismiss()
    }
  });

  useEffect(()=>{
    getMap();
    
    eventEmitter.on('node_list',getMap);

    return () => {
      
      eventEmitter.removeListener('node_list', getMap);

    }
  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>维护</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={()=>addNodePresent()}>
              <IonIcon slot="icon-only" icon={documentTextOutline}></IonIcon>
            </IonButton>
            <IonButton onClick={()=>addTypePresent()}>
              <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonButtons slot="end" style={{borderLeft:'1px solid #eeeeee'}}>
            <IonButton routerLink='/maintain/accounts'>
              <IonIcon slot="icon-only" icon={peopleOutline}></IonIcon>
            </IonButton>
            <IonButton routerLink='/maintain/sites'>
              <IonIcon slot="icon-only" icon={colorFilterOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>

      </IonHeader>
      <IonContent fullscreen>

        <IonGrid >
        {
          map && map.types &&
          map.types.map((type:any)=>(
            <IonItem routerLink={ '/maintain/type/' + type.uuid } key={type.uuid} detail button >
              <IonIcon icon={folderOutline } slot="start" />
              <IonLabel>
                {type.name}
              </IonLabel>
            </IonItem>
          ))
        }
                {
        map && map.nodes &&
          map.nodes.map((node:any)=>(
            <IonItem routerLink={ '/maintain/node/' + node.uuid } key={node.uuid} detail button >
              <IonIcon icon={ documentTextOutline } slot="start" />
              <IonLabel>
                <h4>{node.name}</h4>
                <p>{node.intro}</p>
                <p>{node.url}</p>
              </IonLabel>
              
            </IonItem>
          ))
        }
        </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default Maintain;

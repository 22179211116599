import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNavLink, IonNote, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonModal } from '@ionic/react';

import {restPatch, useRestGet, useRestLoad} from '../../hooks/WildWebHttp'
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NodeItem from '../../components/NodeItem';
import MaintainNodeItem from '../../components/MaintainNodeItem';
import MaintainNodePicker from '../../modals/MaintainTypePicker';
import MaintainExplorer from '../../modals/MaintainExplorer';
import { checkmarkOutline, chevronForwardOutline, documentTextOutline, folderOutline, handRightOutline } from 'ionicons/icons';



const Node: React.FC = () => {
  
  const history = useHistory();
  
  const [accounts,listAccounts] = useRestGet('/rest/maintain/accounts' );


  useEffect(()=>{
    listAccounts();
  },[]);



  if (!accounts){
    return <IonPage>Loading ...</IonPage>
  }else{
    return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>维护节点</IonTitle>
          
        </IonToolbar>
        </IonHeader>

        <IonContent id="content">

        { accounts.map((item:any)=>(
              <IonItem routerLink={ '/maintain/account/' + item.uuid } key={item.uuid}>

              <IonLabel>
                <h3>{item.email}</h3>
                <IonNote>
                  {item.uuid}
                </IonNote>
              </IonLabel>

            </IonItem>
            ))
        }
        </IonContent>
      </IonPage>
    );
  }
};

export default Node;

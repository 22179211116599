import { IonButton, IonCard, IonIcon, IonItem, IonLabel, IonRouterLink } from '@ionic/react';
import { bookmarkOutline, documentTextOutline, folderOutline, informationCircleOutline } from 'ionicons/icons';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext, eventEmitter } from '../common/global';
import { rpcCall, useRpcCall } from '../hooks/WildWebHttp';
import './SiteCard.css';

interface ContainerProps {
  node: any;
}

const NodeCard: React.FC<ContainerProps> = ({ node }) => {

  const context = useContext(AppContext);
  
  return (
    <IonCard className='node_card'>
      <IonItem key={node.uuid} lines='none'>
          <IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
                
          <IonLabel>
            {node.name}
          </IonLabel>
          
      </IonItem >
      <IonItem>
        { node.intro &&
      
          <IonLabel><p>{node.intro}</p></IonLabel>
        }
      </IonItem>
      
      <IonItem  detail button >
      <IonRouterLink href={node.url} target="_blank">
        {node.url}
      </IonRouterLink>
      <IonLabel slot='end'>
      {
        node.tags &&
        node.tags.map((tag:any)=>(
          <Link to={'/tag/' + tag.uuid} key={tag.uuid}>{tag.name}</Link>
        ))
      }
      </IonLabel>

      </IonItem>
      
    </IonCard>
  );
};

export default NodeCard;

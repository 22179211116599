import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonNavLink, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';

import {rpcCall, useRpcCall} from '../hooks/WildWebHttp'
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NodeItem from '../components/NodeItem';
import { closeOutline, sendOutline } from 'ionicons/icons';


const Register: React.FC<{close:()=>void,finish:()=>void}> = ({close,finish}) => {
  
  const history = useHistory();

  const [email,setEmail] = useState('')
  const [code,setCode] = useState('')
  const [password,setPassword] = useState('')

  const [ sendcode, callSendcode ] = useRpcCall('/rpc/email/send_code')

  const [present] = useIonToast()
  
  useEffect(()=>{
  },[]);

  useEffect(()=>{
    if (sendcode){
      present('邮件已发送，请留意查收！',3000);
    } 
  },[sendcode])

 
  const register = ()=>{
    rpcCall('/rpc/account/register',{email:email,code:code,password:password},(args:any)=>{
      console.log('register success:' + args);
      if (args.code == 200){
        finish();
      }else{
        alert(args.message);
      }
    })
  }
  

  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
            <IonButton  onClick={ close} >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
        </IonButtons>
        <IonTitle>注册</IonTitle>
      </IonToolbar>

      </IonHeader>
      <IonContent id="content">
        <IonItem>
          <IonLabel position="stacked">电子邮箱</IonLabel>
          <IonInput placeholder="电子邮箱地址" value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
         
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">电子邮箱验证码</IonLabel>
          <IonInput placeholder="六位数字验证码" value={code} onIonChange={e => setCode(e.detail.value!)}></IonInput>
          <IonButton slot="end" fill='outline' color='secondary' disabled={!!sendcode} onClick={()=>{
            callSendcode({email:email})
          }}>点击发送</IonButton>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">密码</IonLabel>
          <IonInput placeholder="登录密码" value={password} onIonChange={e => setPassword(e.detail.value!)} type="password"></IonInput>
        </IonItem>
        
        <IonButton style={{margin:'16px'}} expand="block" onClick={()=>register()}>注册</IonButton>
      
      </IonContent>
    </IonPage>
  );
};

export default Register;

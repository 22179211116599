import React, { useState } from 'react';
import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonNavLink, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonModal ,IonInput, IonLabel, IonList, IonButton} from '@ionic/react';
import MaintainNodePicker from './MaintainTypePicker';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { restPost } from '../hooks/WildWebHttp';


const MaintainAddType: React.FC<{parent:{[key:string]:any}, close:()=>void,confirm:(pNode:{[key:string]:any})=>void}> = ({parent,close,confirm}) =>{
  
    const history = useHistory();
    
    const [parentNode,setParentNode] = useState<{[key:string]:any}>(parent)
    const [name,setName] = useState<string>()
    const [url,setUrl] = useState<string>()

    const [nodePickerPresent, nodePickerDismiss] = useIonModal(MaintainNodePicker, {
        close:()=>nodePickerDismiss(),confirm:(pNode:{[key:string]:any})=>{
            setParentNode(pNode)

          nodePickerDismiss()
        }
      });

      
  return (

    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton  onClick={ close} >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>新建类型</IonTitle>
          <IonButtons slot="end">
              <IonButton  onClick={()=>{

                restPost('/rest/maintain/type',undefined,{
                    parentUuid:parentNode.uuid,
                    name:name
                },confirm);


              }} >
                <IonIcon icon={checkmarkOutline}></IonIcon>
              </IonButton>
            </IonButtons>
        </IonToolbar>

        </IonHeader>
        <IonContent id="content">
 
        <IonList>
            <IonItem onClick={()=>nodePickerPresent()} button>
                <IonLabel>
                    { parentNode.name
                        ?<p>{parentNode.name}</p>
                        :<p>[请设置父类型]</p>
                    }
                </IonLabel>
                <IonLabel slot='end'>
                    <p>设置</p>
                </IonLabel>
            </IonItem>
            
            <IonItem>
                <IonLabel position="stacked">名称</IonLabel>
                <IonInput placeholder="Enter text" value={name} onIonChange={(e)=>setName(e.detail.value!)}></IonInput>
            </IonItem>
            
        </IonList>
        </IonContent>
      </IonPage>


  );
}
export default MaintainAddType;
import { IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonRouterLink } from '@ionic/react';
import { bookmarkOutline, documentTextOutline, folderOutline, informationCircleOutline } from 'ionicons/icons';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext, eventEmitter } from '../common/global';
import { rpcCall, useRpcCall } from '../hooks/WildWebHttp';
import './NodeCard.css';

interface ContainerProps {
  node: any;
}

const NodeCard: React.FC<ContainerProps> = ({ node }) => {

  const context = useContext(AppContext);
  
  return (
    <IonCard className='node_card'>
      <IonItem key={node.uuid} lines='none'>
        { node.logo?
          <IonImg src={node.logo} style={{width:'24px',height:'24px',margin:'4px',marginRight:'16px'}} slot="start"></IonImg>    
          :<IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
        }
            
          <IonLabel>
            {node.name}
          </IonLabel>
          { context.account && context.account.user &&
          <IonButton fill='clear' onClick={()=>{
            rpcCall('/rpc/user/favor',{node_uuid:node.uuid},()=>{
              eventEmitter.emit("user_favors");
            });
          }}>
            <IonIcon icon={bookmarkOutline} />
          </IonButton>
          }
          {context.account && context.account.maintainer &&
          <IonButton fill='clear' routerLink={ '/node/' + node.uuid } >
            <IonIcon icon={informationCircleOutline} />
          </IonButton>
          }
      </IonItem >
      <IonItem>
        { node.intro &&
      
          <IonLabel><p>{node.intro}</p></IonLabel>
        }
      </IonItem>
      
      <IonItem  detail button >
      <IonRouterLink href={node.url} target="_blank">
        {node.url}
      </IonRouterLink>
      <IonLabel slot='end'>
      {
        node.tags &&
        node.tags.map((tag:any)=>(
          <Link to={'/tag/' + tag.uuid} key={tag.uuid}>{tag.name}</Link>
        ))
      }
      </IonLabel>

      </IonItem>
      
    </IonCard>
  );
};

export default NodeCard;

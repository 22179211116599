

export const splitArray= (arr:[], len:any)=> {
    // while(arr.length % len != 0){
    //   arr.push({});
    // }
    let arrLen = arr.length;
    let result = [];
    for (let i = 0; i < arrLen;  i += len) {
        result.push(arr.slice(i, i + len));
    }

    return result;
}
import { Redirect, Route, useHistory } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRoute,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonToast
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cogOutline, ellipse, footstepsOutline, square, triangle } from 'ionicons/icons';

import { homeOutline , listOutline ,personOutline, cameraOutline,settingsOutline} from 'ionicons/icons';


import Home from './pages/Home';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Node from './pages/Node';
import Tag from './pages/Tag';
import Search from './pages/Search';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {AppContext,eventEmitter} from './common/global'
import { usePreferences } from './hooks/usePreferences';
import Track from './pages/Track';
import Maintain from './pages/Maintain';
import MaintainNode from './pages/maintain/MaintainNode';
import { useEffect, useState } from 'react';
import { rpcCall, useRpcCall } from './hooks/WildWebHttp';
import Type from './pages/Type';
import MaintainType from './pages/maintain/MaintainType';
import MaintainAccounts from './pages/maintain/MaintainAccounts';
import MaintainSites from './pages/maintain/MaintainSites';

setupIonicReact();


const App: React.FC = () => {


  const history = useHistory();

  const [toastPresent,toastDismiss] = useIonToast()


  const [client,setClient] = usePreferences('client');
  const [account,setAccount] = usePreferences('account');
  const [user,setUser] = usePreferences('user');
  const [maintainer,setMaintainer] = usePreferences('maintainer');

  const [checkin , callCheckin] = useRpcCall('/rpc/client/checkin');



  useEffect(()=>{

    if (client == undefined) return;

    console.log('re define emitter ...');

    const f = ()=>{
      console.log('call re checkin..');

      let args = {
        uuid:client.uuid,
        token:client.token
      };

      console.log(JSON.stringify(args));

      rpcCall('/rpc/client/checkin',args,()=>{

        window.location.reload();
        toastPresent('会话过期，已自动重连。。。',3000);

      });

  
    }

    
    eventEmitter.on('session_invalid',f);

    return () => {
      
      console.log('remove emitter listener..');
      eventEmitter.removeListener('session_invalid', f);

    }
  },[client]);

  useEffect(() => {
    console.log('client:' + JSON.stringify(client));
    console.log('checkin:' + checkin);

    if (client === undefined) return;
    if (checkin) {
      console.log('+++ true');
      return;
    }else{

      console.log('+++ false');
    }

    if (client){
      callCheckin({
        uuid:client.uuid,
        token:client.token
      });
    }else{
      callCheckin({});
    }

    
  }, [client]);


  useEffect(() => {

    if (!checkin) return;

    if (checkin.code == 200){
      setClient(checkin.data.client);
      setAccount(checkin.data.account);
    }else{
      toastPresent(checkin.message,3000);
    }
  },[checkin]);


  console.log('===== account: ' + account);

  if (account === undefined || checkin === undefined){
    return ;
  }

  return <AppContext.Provider value={{client,setClient, account,setAccount }}>
  <IonApp>
    <IonReactRouter basename='route'>
      <IonRouterOutlet>
      {account?
        <Route path='/tab_:tab'>
          <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/tab_home">
              <Home />
            </Route>
            <Route exact path="/tab_track">
              <Track />
            </Route>
            <Route exact path="/tab2">
              <Tab2 />
            </Route>
            <Route path="/tab3">
              <Tab3 />
            </Route>
            <Route exact path="/tab_maintain">
              <Maintain />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/tab_home">
              <IonIcon aria-hidden="true" icon={homeOutline} />
              <IonLabel>首页</IonLabel>
            </IonTabButton>
            {account && account.user &&
            <IonTabButton tab="track" href="/tab_track">
              <IonIcon aria-hidden="true" icon={listOutline} />
              <IonLabel>记录</IonLabel>
            </IonTabButton>
            }
            {/* <IonTabButton tab="tab2" href="/tab2">
              <IonIcon aria-hidden="true" icon={listOutline} />
              <IonLabel>收藏</IonLabel>
            </IonTabButton> */}
            {/* <IonTabButton tab="tab3" href="/tab3">
              <IonIcon aria-hidden="true" icon={settingsOutline} />
              <IonLabel>设置</IonLabel>
            </IonTabButton> */}
            {account && account.maintainer &&
            <IonTabButton tab="maintain" href="/tab_maintain">
              <IonIcon aria-hidden="true" icon={cogOutline} />
              <IonLabel>维护</IonLabel>
            </IonTabButton>
            }
          </IonTabBar>
        </IonTabs>
        </Route>
        :<></>
      }
      {
      account?
        <Route exact path="/">
          <Redirect to="/tab_home" />
        </Route>
        :
        <Route exact path="/">
          <Home />
        </Route>
      }
      
      <Route exact path="/type/:uuid">
        <Type />
      </Route>
      <Route exact path="/type2/:uuid">
        <Type />
      </Route>
      <Route exact path="/node/:uuid">
        <Node />
      </Route>

      <Route exact path="/tag/:uuid">
        <Tag />
      </Route>

      <Route exact path="/search/:keyword">
        <Search />
      </Route>

      <Route exact path="/maintain/type/:uuid">
        <MaintainType />
      </Route>
      <Route exact path="/maintain/node/:uuid">
        <MaintainNode />
      </Route>

      <Route exact path="/maintain/accounts">
        <MaintainAccounts />
      </Route>
      <Route exact path="/maintain/sites">
        <MaintainSites />
      </Route>


      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  </AppContext.Provider>
};

export default App;

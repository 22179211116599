import { IonIcon, IonItem, IonLabel, IonRouterLink } from '@ionic/react';
import { documentTextOutline, folderOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import './NodeItem.css';

interface ContainerProps {
  node: any;
}

const NodeItem: React.FC<ContainerProps> = ({ node }) => {
  return (
    <>
      <IonItem routerLink={ '/node/' + node.uuid } key={node.uuid} detail button lines='none'>
          <IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
                
          <IonLabel>
            {node.name}
          </IonLabel>
          
      </IonItem>
      { node.url &&
      <IonItem  >
      <IonRouterLink href={node.url}>
        {node.url}
      </IonRouterLink>
      <IonLabel slot='end'>
      {
        node.tags &&
        node.tags.map((tag:any)=>(
          <Link to={'/tag/' + tag.uuid} key={tag.uuid}>{tag.name}</Link>
        ))
      }
      </IonLabel>

      </IonItem>
      }
    </>
  );
};

export default NodeItem;

import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonNavLink, IonNote, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Node.css';
import {useRestLoad, useRpcCall} from '../hooks/WildWebHttp'
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import NodeItem from '../components/NodeItem';
import { AppContext } from '../common/global';
import {splitArray} from '../common/utils';
import NodeCard from '../components/NodeCard';
import { bookmark, bookmarkOutline } from 'ionicons/icons';

const Node: React.FC = () => {
  
  const {uuid} = useParams<{uuid: string}>()
  const [node,getNode] = useRestLoad('/rest/node/' );

  const [favor,rpcFavor] = useRpcCall('/rpc/user/favor');

  const context = useContext(AppContext);
  
  useEffect(()=>{
    getNode(uuid)
  },[uuid]);

  useEffect(()=>{
    // let h = $('#content').height();
    // console.log('======' + h);
    // if (h){
    //   // $('#frame').height(h-120);
    // }
  });

  if (!node.ready){
    return <IonPage></IonPage>
  }else{


    const FILE_BASE_URL = window.location.origin.replace(':8100','') + '/files/'  

    return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>{node.data.detail.name}</IonTitle>
        </IonToolbar>
        <IonBreadcrumbs class='breadcrumbs'>
          <IonBreadcrumb href={'/'} key={'home'}>首页</IonBreadcrumb>
          {
            node.data.paths.map((path:any)=>( path.uuid != '265a37db-7601-42f9-b676-651e87ec55e9' &&
              <IonBreadcrumb routerLink={'/type/' + path.uuid} key={path.uuid}>{path.name}</IonBreadcrumb>
            ))
          }
          </IonBreadcrumbs>

        </IonHeader>
        <IonContent id="content">

            <IonGrid>
              <IonRow>
              <IonCol size='12' size-md='8' size-lg='8'>
              <IonItem> 
                <IonLabel>{node.data.detail.name}</IonLabel>
                <IonButton fill='clear' onClick={()=>{
                  rpcFavor({node_uuid:node.data.detail.uuid});
                }}>
                  <IonIcon icon={bookmarkOutline} />
                </IonButton>
              </IonItem>
              <IonItem>
                { node.data.detail.direct === true &&
                  <IonNote>即用，无需注册</IonNote>
                }
                
              </IonItem>
              <IonItem> 
                <IonLabel ><p>{node.data.detail.intro}</p></IonLabel>
                {node.data.site &&
                <p>
                [<a href={node.data.site.url}>{node.data.site.name}</a>]
                </p>
                }
              </IonItem>
              
              

              <IonItem> 
                <a target='_blank' href={ node.data.detail.url } >{node.data.detail.url}</a>
              </IonItem>

              </IonCol>
              <IonCol>
                <a className='tag' href='#'>图像</a>
                <a className='tag' href='#'>设计</a>
                <a className='tag' href='#'>制作</a>
              </IonCol>
              </IonRow>
            </IonGrid>
          
        </IonContent>
      </IonPage>
    );
  }
};

export default Node;

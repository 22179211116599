import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonLoading, IonNavLink, IonPage, IonRow, IonSkeletonText, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import {useRestLoad, useRpcCall} from '../hooks/WildWebHttp'
import { useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NodeItem from '../components/NodeItem';
import { AppContext } from '../common/global';
import {splitArray} from '../common/utils';
import NodeCard from '../components/NodeCard';
import './Type.css';
import { Link } from 'react-router-dom';

const Type: React.FC = () => {
  
  const {uuid} = useParams<{uuid: string}>()
  const [type,getType] = useRestLoad('/rest/type/' );
  const location = useLocation();

  
  useEffect(()=>{
    if (uuid === undefined ) return;
    console.log('type uuid: ' + uuid);

    getType(uuid)
  },[uuid]);



  // if (!type.ready){
  //   return <IonPage></IonPage>
  // }else{

    return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>{type.ready && type.data.detail.name}</IonTitle>
        </IonToolbar>
        <IonBreadcrumbs class='breadcrumbs'>
          <IonBreadcrumb href={'/'} key={'home'}>首页</IonBreadcrumb>
          {
            type.ready &&
            type.data.paths.map((path:any)=>(
              <IonBreadcrumb routerLink={(location.pathname.startsWith('/type/')? '/type2/':'/type/')  + path.uuid} routerDirection="back" key={path.uuid}>{path.name}</IonBreadcrumb>
            ))
          }
          </IonBreadcrumbs>

        </IonHeader>

        <IonContent id="content">

        {type.ready?
        <>
        <IonGrid id='types'  >
            { type.data.childs && 
              splitArray(type.data.childs,12).map((types:any,i:any)=>(

                <IonRow key={i}>
                  {
                    types.map((type:any,j:any)=>(
                      <IonCol class='type'  key={type.uuid || j} size="3" size-sm="2" size-md="2" size-lg="1">
                        <Link to={ (location.pathname.startsWith('/type/')? '/type2/':'/type/') + type.uuid}>{type.name}</Link>
                      </IonCol>  
                    ))
                  }
                </IonRow>
              ))
            }

        </IonGrid>
        <IonGrid >
          {
            type.data.nodes &&
            splitArray(type.data.nodes,4).map((rows:any,i:any)=>( 
              
              <IonRow key={i}>
              {
                rows.map((col:any,j:any)=>(
                  <IonCol key={col.uuid || j} size="12" size-sm="6" size-lg="3">
                    <NodeCard node={col} key={col.uuid}></NodeCard>
                    {/* <Link to={'/node/'+ type.uuid}>{type.name}</Link> */}
                    </IonCol>  
                ))
              }
            </IonRow>

            ))
          }
        </IonGrid>  
        </>:<center><IonSpinner></IonSpinner></center>
        }
        </IonContent>
        
      </IonPage>
    );
  }
// };

export default Type;

import { IonButton, IonButtons, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonMenu, IonMenuButton, IonMenuToggle, IonNavLink, IonPage, IonRouterLink, IonRow, IonSearchbar, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import { useRestGet, useRestList, useRpcCall } from '../hooks/WildWebHttp';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NodeItem from '../components/NodeItem';
import { AppContext } from '../common/global';
import { logInOutline, logOutOutline, mailOutline, pushOutline, refreshCircleOutline } from 'ionicons/icons';
import {splitArray} from '../common/utils';
import NodeCard from '../components/NodeCard';
import Login from '../modals/Login';
import Register from '../modals/Register';

const Home: React.FC = () => {
  const history = useHistory();

  const [home,getHome] = useRestGet('/rest/home');
  const [searchText, setSearchText] = useState('');
  const [logout,rpcLogout] = useRpcCall('/rpc/account/logout');
  const [loginPresent, loginDismiss] = useIonModal(Login, {
    close:()=>loginDismiss()
  });
  const [registerPresent, registerDismiss] = useIonModal(Register, {
    close:()=>registerDismiss(),
    finish:()=>{
      registerDismiss();
      loginPresent();
    }
  });
  

  const context = useContext(AppContext);

  useEffect(()=>{
    getHome()
  },[]);

  useEffect(()=>{
    if (logout && logout.code == 200){

      context.setAccount(null);
      history.replace('/');
      // window.location.href = '/';
    }
  },[logout]);

  // const splitArray = (arr:[{}], len:any)=> {
  //   while(arr.length % len != 0){
  //     arr.push({});
  //   }
  //   let arrLen = arr.length;
  //   let result = [];
  //   for (let i = 0; i < arrLen;  i += len) {
  //       result.push(arr.slice(i, i + len));
  //   }
  //   console.log(result);

  //   return result;
  // }

  var search = (e:any,text:any)=>{
      console.log('search:' + text);
      if (e.key == 'Enter'){
        history.push('/search/' + text);
        
      }
  }
  
  

  return (
    <>
    <IonMenu contentId="main-content" side='end'>
      <IonHeader>
        <IonToolbar>
          <IonTitle>About</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem onClick={()=>window.location.reload()}>
          <IonIcon icon={refreshCircleOutline} slot="start" />
          <IonLabel>刷新</IonLabel>
        </IonItem>
        {!context.account &&
        <IonMenuToggle>
          <IonItem onClick={()=>loginPresent()}>
            <IonIcon icon={logInOutline} slot="start" />
            <IonLabel>登录</IonLabel>
          </IonItem>
        </IonMenuToggle>
        }
        {!context.account &&
          <IonMenuToggle>
            <IonItem onClick={()=>registerPresent()}>
              <IonIcon icon={pushOutline} slot="start" />
              <IonLabel>注册</IonLabel>
            </IonItem>
          </IonMenuToggle>
        }

        {context.account &&
          <IonItem >
            <IonIcon icon={mailOutline} slot="start" />
            <IonLabel>{context.account.email}</IonLabel>
          </IonItem>
        }
        {context.account &&
          <IonItem onClick={()=>{
            rpcLogout({
              uuid:context.client && context.client.uuid,
              token:context.client && context.client.token
            },()=>{
              context.setAccount(null);
              window.location.href = '/';
            });
          }}>
            <IonIcon icon={logOutOutline} slot="start" />
            <IonLabel>登出</IonLabel>
          </IonItem>
        }
      </IonContent>
    </IonMenu>
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonImg slot='start' src='/assets/logo.png' style={{width:'32px',height:'32px',marginLeft:'8px',marginTop:'6px'}}></IonImg>
          <IonTitle>集具网</IonTitle>
           <IonButtons slot="end">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
           <IonSearchbar className="searchbar" value={searchText} animated={true} placeholder="搜索" enterkeyhint="done" inputmode="search" style={{marginTop:'6px'}} onIonChange={e => setSearchText(e.detail.value!)} onKeyPress={(e)=>search(e,searchText)} ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <IonGrid id='types' >
            { home && home.types && 
              splitArray(home.types,12).map((types:any,i:any)=>(

                <IonRow key={i}>
                  {
                    types.map((type:any,j:any)=>(
                      <IonCol class='type'  key={type.uuid || j} size="3" size-sm="2" size-md="2" size-lg="1">
                        <IonRouterLink routerLink={'/type/'+ type.uuid} routerDirection="forward">{type.name}</IonRouterLink>
                      </IonCol>  
                    ))
                  }
                </IonRow>
              ))
            }

        </IonGrid>
        
        <IonGrid>
          {
            home && home.nodes &&
            splitArray(home && home.nodes,4).map((rows:any,i:any)=>( 
              
              <IonRow key={i}>
              {
                rows.map((col:any,j:any)=>(
                  <IonCol key={col.uuid || j} size="12" size-sm="6" size-lg="3">
                    <NodeCard node={col} key={col.uuid}></NodeCard>
                    {/* <Link to={'/node/'+ type.uuid}>{type.name}</Link> */}
                    </IonCol>  
                ))
              }
            </IonRow>

            ))
          }


        </IonGrid>
      </IonContent>
      { !context.account  && window.location.origin.includes('jiju.site') &&
      <IonFooter className='footer'>
        <p style={{margin:'16px 0 4px', color:'gray'}}>
          Copyright 2023 &copy; jiju.site &nbsp;&nbsp;&nbsp;&nbsp; 投诉邮箱:service@jiju.site <br/>
        </p>
        <div style={{ margin:'0 auto', padding:'8px'}}>
          <a target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/"
            style={{display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}}>
              <p style={{float:'left',height:'20px',lineHeight:'20px',margin:'0px 0px 0px 5px', color:'#939393'}}>沪ICP备16021655号-2</p>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a target="_blank" rel="nofollow" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402010080" 
            style={{display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}}>
            <img src="/assets/beian.png" style={{float:'left'}}/>
            <p style={{float:'left',height:'20px',lineHeight:'20px',margin:'0px 0px 0px 5px', color:'#939393'}}>沪公网安备 31011402010080号</p>
          </a>
        </div>
      
      </IonFooter>
      }
    </IonPage>
    </>
  );
};

export default Home;

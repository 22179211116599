import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect } from 'react';
import { eventEmitter } from '../common/global';
import { splitArray } from '../common/utils';
import ExploreContainer from '../components/ExploreContainer';
import NodeCard from '../components/NodeCard';
import NodeItem from '../components/NodeItem';
import { useRestGet, useRestList, useRestLoad } from '../hooks/WildWebHttp';


const Track: React.FC = () => {

  const [favors,listFavors] = useRestList('/rest/user/favors');
  
  useEffect(()=>{
    listFavors();

    eventEmitter.on('user_favors',listFavors);

    return () => {
      
      eventEmitter.removeListener('user_favors', listFavors);

      console.log('context event removed.. ' );
    }
  },[]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>记录</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
        <IonGrid >
        {
          favors &&
          splitArray(favors,4).map((rows:any,i:any)=>( 
            
            <IonRow key={i}>
            {
              rows.map((col:any,j:any)=>(
                <IonCol key={col.uuid || j} size="12" size-sm="6" size-lg="3">
                  <NodeCard node={col} key={col.uuid}></NodeCard>
                </IonCol>  
              ))
            }
          </IonRow>

          ))
        }

        </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default Track;

import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonNavLink, IonPage, IonRow, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';

import {rpcCall} from '../hooks/WildWebHttp'
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NodeItem from '../components/NodeItem';
import {AppContext} from '../common/global'
import { closeOutline } from 'ionicons/icons';

const Register: React.FC<{close:()=>void}> = ({close}) => {

  const context = useContext(AppContext);
  const history = useHistory();
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const [present] = useIonToast()
  
  useEffect(()=>{
  },[]);

 
  const login = ()=>{
    rpcCall('/rpc/account/login',{email:email,password:password,uuid:context.client.uuid,token:context.client.token},(args:any)=>{
      console.log('login success:' + args);

      if (args.code == 200){

        context.setAccount(args.data.account);

        present(args.message,2000);

        window.location.reload();
      }else{
        alert(args.message);
      }
    })
  }
  

  return (
    <IonPage> 
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
            <IonButton  onClick={ close} >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
        </IonButtons>
        <IonTitle>登录</IonTitle>
      </IonToolbar>

      </IonHeader>
      <IonContent id="content">
        <IonItem>
          <IonLabel position="stacked">电子邮箱</IonLabel>
          <IonInput placeholder="Enter text" type="email" onIonChange={e => setEmail(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">密码</IonLabel>
          <IonInput placeholder="Enter text" type="password" onIonChange={e => setPassword(e.detail.value!)}></IonInput>
        </IonItem>
        
        <IonButton style={{margin:'16px'}} expand="block" onClick={()=>login()}>登录</IonButton>
      
      </IonContent> 
    </IonPage>
  );
};

export default Register;

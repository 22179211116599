import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonNavLink, IonNote, IonPage, IonRow, IonSpinner, IonTitle, IonToggle, IonToolbar, useIonModal } from '@ionic/react';

import {restPatch, useRest, useRestLoad, useRestPatch} from '../../hooks/WildWebHttp'
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NodeItem from '../../components/NodeItem';
import MaintainNodeItem from '../../components/MaintainNodeItem';
import MaintainNodePicker from '../../modals/MaintainTypePicker';
import MaintainExplorer from '../../modals/MaintainExplorer';
import { checkmarkCircle, checkmarkCircleOutline, checkmarkOutline, chevronForwardOutline, documentTextOutline, folderOutline, handRightOutline } from 'ionicons/icons';
import './MaintainNode.css';
import { AppContext, eventEmitter } from '../../common/global';


const Node: React.FC = () => {
  
  const history = useHistory();
  
  const {uuid} = useParams<{uuid: string}>()
  // const [node,getNode] = useRestLoad('/rest/maintain/node/' );
  const [fields,setFields] = useState<{[any:string]:any}>({});
  // const [patchedNode,patchNode] = useRestPatch('/rest/maintain/node/');

  const [node,getNode,patchNode] = useRest('/rest/maintain/node/');
  
  const context = useContext(AppContext);

  const [nodePickerPresent, nodePickerDismiss] = useIonModal(MaintainNodePicker, {
    close:()=>nodePickerDismiss(),confirm:(type:{[any:string]:any})=>{
      // setFields(Object.assign(fields,{typeUuid:type.uuid,typeName:type.name}));
      nodePickerDismiss()

      patchNode(uuid,{'type_uuid':type.uuid},()=>{
        // getNode(uuid);
        eventEmitter.emit("node_list");
        console.log('node list emited!!');
      });

      // restPatch('/rest/maintain/node/' + uuid,{
      //   type_uuid:type.uuid
      // },()=>{
      //   getNode(uuid);
      //   eventEmitter.emit("node_list");
      //   console.log('node list emited!!');
      // });


    }
  });

  const [detailPickerPresent, detailPickerDismiss] = useIonModal(MaintainExplorer, {
    url_:fields.url,
    close:()=>detailPickerDismiss()
  });

  console.log('uuid:' + uuid);

  useEffect(()=>{
    getNode(uuid)
  },[uuid]);

  useEffect(()=>{

    if (node){ 
        setFields({
          name:node.detail.name,
          intro:node.detail.intro,
          url:node.detail.url
        });
    }
  },[node]);


  useEffect(()=>{
    // let h = $('#content').height();
    // console.log('======' + h);
    // if (h){
    //   // $('#frame').height(h-120);
    // }
  });


  return node?
    <IonPage>
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
        </IonButtons>
        <IonTitle>维护节点:{node.detail.name}</IonTitle>
        
      </IonToolbar>
      <IonBreadcrumbs class='breadcrumbs'>

        {
          node.paths.map((path:any)=>(
            <IonBreadcrumb href={'/maintain/type/' + path.uuid} target="_self" key={path.uuid}>{path.name}</IonBreadcrumb>
          ))
        }
        </IonBreadcrumbs>

      </IonHeader>
      <IonContent id="content">
      <IonItemGroup>
        <IonItemDivider>
          <IonLabel>关联</IonLabel>
        </IonItemDivider>
      <IonItem onClick={()=>nodePickerPresent()} detail>
        <IonLabel>
          { node.type ? 
          <h4>{node.type.name}[分类]</h4>
          :<p>[未分类]</p>
          }
        </IonLabel>
      </IonItem>

      { node.site 
      ?<IonItem  button>
        <IonLabel>
          <h4>{node.site.name}</h4>
          <p>{node.site.url}</p>
        </IonLabel>
      </IonItem>
      :<IonItem detail>
        <IonLabel>
          <p>[未关联站点]</p>
        </IonLabel>
        <IonLabel slot='end'>
          <p>设置</p>
        </IonLabel>
      </IonItem>
      }
      </IonItemGroup>

      <IonItemGroup>
        <IonItemDivider>
          <IonLabel>详情</IonLabel>
          <IonButton slot='end' fill='clear' onClick={()=>{
            patchNode(uuid,fields);
          }}>
            <IonIcon slot='icon-only' icon={checkmarkCircleOutline}></IonIcon>
          </IonButton>
        </IonItemDivider>
        <IonItem>
          <IonLabel>UUID</IonLabel>
          <IonLabel slot='end'><p>{ uuid}</p></IonLabel>
        </IonItem>         
        <IonItem href={fields.url} target="_blank" detail >
          <IonLabel>Name</IonLabel>
          <IonInput value={fields.name}  className='rightInput'
            onIonChange={(e)=>setFields(Object.assign(fields,{name:e.detail.value}))} >
          </IonInput>
        </IonItem>
        <IonItem >
          <IonLabel>Intro</IonLabel>
          <IonInput value={fields.intro}  className='rightInput'
            onIonChange={(e)=>setFields(Object.assign(fields,{intro:e.detail.value}))} >
          </IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>URL</IonLabel>
          <IonInput value={fields.url} className='rightInput'
          onIonChange={(e)=>setFields(Object.assign(fields,{url:e.detail.value}))} >
          </IonInput>
          <IonButton slot='end' fill='clear' onClick={()=>{
            detailPickerPresent();
          }}>
            <IonIcon icon={chevronForwardOutline}></IonIcon>
          </IonButton>
        </IonItem>    
      
        </IonItemGroup>
        <IonItemGroup>
        <IonItemDivider>
          <IonLabel>状态</IonLabel>
        </IonItemDivider> 
        <IonItem>
          <IonLabel>即用，无需注册？</IonLabel>
          <IonToggle slot="end" checked={node.detail.direct==true} onIonChange={(e)=>{
            patchNode(uuid,{'direct':e.detail.checked});
          }}></IonToggle>
        </IonItem>
        <IonItem>
          <IonLabel>免费</IonLabel>
          <IonToggle slot="end" checked={node.detail.free==true} onIonChange={(e)=>{
            patchNode(uuid,{'free':e.detail.checked});
          }}></IonToggle>
        </IonItem>
            
        <IonItem>
          <IonLabel>Disable</IonLabel>
          <IonToggle slot="end" checked={node.detail.disable==true} onIonChange={(e)=>{
            patchNode(uuid,{'disable':e.detail.checked});
          }}></IonToggle>
        </IonItem>
    
        
      </IonItemGroup>

      {
        node.childs && node.childs.length > 0 &&
        <IonItemGroup>

          <IonItemDivider>
            <IonLabel>子节点</IonLabel>
          </IonItemDivider>

          {
            node.childs.map((node:any)=>(
              <IonItem routerLink={ '/maintain/node/' + node.uuid } key={node.uuid} detail button lines='none'>
              <IonIcon icon={node.url? documentTextOutline:folderOutline } slot="start" />
              <IonLabel>
                {node.name}
              </IonLabel>
            </IonItem>
            ))
          }

        </IonItemGroup>
      }
      </IonContent>
    </IonPage>:
    <center><IonSpinner></IonSpinner></center>
  
};

export default Node;
